import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { LgTitle } from '../elements/Titles';
import { XsParagraph } from '../elements/Paragraphs';
import { UnorderedList } from '../elements/Lists';

const CopyrightNotice = () => (
  <Layout>
    <SEO title="Copyright Notice" />
    <Container>
      <Row marginBottom="144">
        <Column>
          <LgTitle>Copyright Notice</LgTitle>
          <XsParagraph>
            Copyright infringement is a criminal offence. It is also actionable
            in a civil suit.
          </XsParagraph>
          <XsParagraph>
            At Wise Publishing, Inc. ("Wise Publishing"), we go to considerable
            effort to ensure we provide you with timely original information. We
            take copyright infringement very seriously. All Wise Publishing
            materials are copyright. Wise Publishing will protect its material
            (including its writers and licensors) copyright to the full extent
            of the law. No part of this publication may be reproduced,
            distributed, or transmitted in any form or by any means, including
            photocopying, recording, or other electronic or mechanical methods,
            or stored in any other website or included in any public or private
            electronic retrieval system or service without our prior written
            permission except in the limited circumstances below.
          </XsParagraph>
          <XsParagraph>
            You can use materials for your own personal circumstances.
            Otherwise, you must not use it for any commercial gain.
          </XsParagraph>
          <XsParagraph>
            You are permitted to print and download extracts from this Site for
            your own personal use or send them to a friend on the following
            basis:
          </XsParagraph>
          <UnorderedList>
            <li>
              there is no modification in any way of the documents or related
              graphics;
            </li>
            <li>
              no graphics on our Site are used separately from accompanying
              text; and
            </li>
            <li>
              our copyright trademark notices (or our licensor's) and a
              permission notice appear in all copies.
            </li>
          </UnorderedList>
          <XsParagraph>
            For further information or to request permission to republish,
            contact:
          </XsParagraph>
          <XsParagraph>licensing@wisepublishing.com</XsParagraph>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default CopyrightNotice;
